import * as React from "react";
import PropTypes from "prop-types";
import { graphql } from "gatsby";
import { Helmet } from "react-helmet";
import { readingTime as readingTimeHelper } from "@tryghost/helpers";

import { Layout } from "../components";
import { PostDefaultHeader, PostRelatedReadings } from "../components/common";
import { MetaData } from "../components/common/meta";

/**
 * Single post view (/:slug)
 *
 * This file renders a single post and loads all the content.
 *
 */
const BlogPost = ({ data, location }) => {
  const post = data.ghostPost;
  const relatedPosts = data.relatedPosts.edges;
  const latestPosts = data.latestPosts.edges;
  const readingTime = readingTimeHelper(post);

  return (
    <>
      <MetaData data={data} location={location} type="article" />
      <Helmet>
        {post.codeinjection_styles ? (
          <style type="text/css">{`${post.codeinjection_styles}`}</style>
        ) : null}
      </Helmet>
      <Layout title={post.title}>
        <div className="mt-20 mb-32 space-y-24">
          <article>
            <PostDefaultHeader post={post} readingTime={readingTime} />
            <section className="mt-10">
              {/* The main post content */}
              <section
                className="content-body load-external-scripts"
                dangerouslySetInnerHTML={{ __html: post.html }}
              />
            </section>
          </article>
          {relatedPosts.length > 0 ? (
            <PostRelatedReadings posts={relatedPosts} />
          ) : (
            <PostRelatedReadings posts={latestPosts} />
          )}
        </div>
      </Layout>
    </>
  );
};

BlogPost.propTypes = {
  data: PropTypes.shape({
    ghostPost: PropTypes.shape({
      codeinjection_styles: PropTypes.object,
      title: PropTypes.string.isRequired,
      html: PropTypes.string.isRequired,
      feature_image: PropTypes.string,
      feature_image_alt: PropTypes.string,
      feature_image_caption: PropTypes.string,
      published_at_pretty: PropTypes.string,
      published_at: PropTypes.string,
      custom_template: PropTypes.string,
      reading_time: PropTypes.number,
      primary_author: PropTypes.shape({
        name: PropTypes.string.isRequired,
        slug: PropTypes.string.isRequired,
        profile_image: PropTypes.string,
      }).isRequired,
      primary_tag: PropTypes.shape({
        name: PropTypes.string.isRequired,
        slug: PropTypes.string.isRequired,
      }).isRequired,
    }).isRequired,
    relatedPosts: PropTypes.shape({
      edges: PropTypes.arrayOf(
        PropTypes.shape({
          node: PropTypes.shape({
            slug: PropTypes.string.isRequired,
            title: PropTypes.string.isRequired,
            feature_image: PropTypes.string,
            feature_image_alt: PropTypes.string,
            excerpt: PropTypes.string,
            published_at_pretty: PropTypes.string,
            published_at: PropTypes.string,
            authors: PropTypes.arrayOf(
              PropTypes.shape({
                name: PropTypes.string.isRequired,
              }).isRequired
            ).isRequired,
          }),
        })
      ),
    }),
  }).isRequired,
  location: PropTypes.object.isRequired,
};

export default BlogPost;

export const query = graphql`
  query GhostPostQuery($slug: String!, $id: String!, $regexTags: String!) {
    ghostPost(slug: { eq: $slug }) {
      ...GhostPostFields
    }
    relatedPosts: allGhostPost(
      filter: {
        id: { ne: $id }
        tags: { elemMatch: { slug: { regex: $regexTags } } }
      }
      sort: { order: DESC, fields: [published_at] }
      limit: 3
    ) {
      edges {
        node {
          ...RelatedPostFields
        }
      }
    }
    latestPosts: allGhostPost(
      filter: { id: { ne: $id } }
      sort: { order: DESC, fields: [published_at] }
      limit: 3
    ) {
      edges {
        node {
          ...RelatedPostFields
        }
      }
    }
  }
`;
